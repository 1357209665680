import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";
import SpinnerCustom from "./SpinnerCustom";

type TinyMCEEditorProps = {
  value: any;
  handleEditorChange: (stringifiedHtmlValue: string) => void;
  onBlur?: (event: any) => void;
  height?: number;
};

const TinyMCEEditor = ({
  value,
  handleEditorChange,
  onBlur,
  height,
}: TinyMCEEditorProps) => {
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);

  const handleEditorInit = () => {
    setIsEditorLoaded(true);
  };

  return (
    <>
      {!isEditorLoaded && <SpinnerCustom />}
      <div className={`${!isEditorLoaded ? "hidden" : ""}`}>
        <Editor
          apiKey={process.env.NEXT_PUBLIC_TINY_MCE_API_KEY}
          value={value}
          init={{
            height: height || 300,
            menubar: false,
            plugins: [
              "lists",
              "checklist",
              "image",
              "autosave",
              "powerpaste",
              "table",
              "emoticons",
              "charmap",
              "preview",
              "pagebreak",
              "hr",
            ],
            toolbar:
              "fontsize bold italic underline strikethrough forecolor backcolor removeformat bullist numlist",
            toolbar_location: "bottom",
            toolbar_mode: "wrap",
            content_style:
              "body { font-family:Montserrat,Arial,sans-serif; font-size:12px }",
            autosave_restore_when_empty: false,
            autosave_retention: "3600m",
            autosave_interval: "5s",
            autosave_prefix: "tinymce-autosave-",
            powerpaste_allow_local_images: true,
            powerpaste_word_import: "prompt",
            color_default_background: "yellow",
            paste_block_drop: true,
            branding: false,
            elementpath: false,
            statusbar: false,
            toolbar_items_size: "small",
            setup: (editor) => {
              editor.on("init", handleEditorInit);
            },
          }}
          onEditorChange={(a) => handleEditorChange(a)}
          onBlur={onBlur}
        />
      </div>
    </>
  );
};

export default TinyMCEEditor;
