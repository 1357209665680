import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import ErrorModal from "../ErrorModal";
import { parseAxiosError } from "../utils";

export default function withErrorHandling(WrappedComponent: any) {
  return function ErrorHandlingComponent(props: any) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const handleError = useCallback((err: AxiosError) => {
      setErrorMessage(parseAxiosError(err) || "An unexpected error occurred");
      setError(true);
    }, []);

    return (
      <>
        {error && (
          <ErrorModal
            errorMessage={errorMessage}
            open={error}
            setOpen={setError}
          />
        )}
        <WrappedComponent {...props} onError={handleError} />
      </>
    );
  };
}
